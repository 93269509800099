import functions from '../functions.js';
import 'select2';
import 'select2/dist/js/i18n/hr.js';

let id = 0;
const instance = {};

// select2 templates can be defined as data-template="destinations" ...
const selectTemplates = {
    destinations: data => {
        if ( !data.element ) {
            return data.text;
        }
        if(data.placeId && data.placeName) {
            //const $element = $(data.element).addClass(data.element.className).text(data.placeName);
            const $element = $('<span>').addClass(data.element.className).text(data.placeName);
            return $element;
        }
        if(data.text) {
            const $element = $('<span>').addClass(data.element.className).text(data.text);
            let elemClass;
            switch ( (data.id).split(':').length ) {
                case 1: elemClass = 'fw-bold'; break;
                case 2: elemClass = 'ps-3 fw-bold'; break;
                case 3: elemClass = 'ps-3 fw-bold'; break;
                case 4: elemClass = 'ps-4'; break;
                default: elemClass = 'h5'; break;
            }
            $element.addClass(elemClass);
            return $element;
        }
    },
};

class Select2 {
    // data-templates
    // data-data
    // data-allow-clear
    // data-nosearch
    // placeholder
    constructor(elem) {
        this.id = ++id;
        instance[this.id] = this;

        this.$elem = $(elem);
        const config = { dropdownParent: this.$elem.parent(), language: functions.getLang() };
        config.allowClear = this.$elem.attr('data-allow-clear');

        // find select2 which has an attribute data-data
        const data = this.$elem.attr('data-data');
        if ( data ) {
            try {
                config.data = JSON.parse(data);
            } catch (e) {}
        } 
        else if ( this.$elem.attr('data-special') ) {
            let newData = $('body').attr('data-select-' + this.$elem.attr('data-special'));
            config.data = JSON.parse(newData);
        }

        if ( this.$elem.attr('data-template') ) {
            config.templateResult = selectTemplates[this.$elem.attr('data-template')];
        }
        if ( this.$elem.attr('data-template-selection') ) {
            config.templateSelection = selectTemplates[this.$elem.attr('data-template-selection')];
        }

        if ( this.$elem.attr('data-nosearch') !== undefined ) {
            config.minimumResultsForSearch = Infinity;
        }

        let placeholder = this.$elem.attr('placeholder');
        this.$elem.removeAttr('placeholder');
        config.placeholder = placeholder || ' ';
        
        this.$elem.select2(config)
            .on('select2:clear', () => {
                this.$elem[0].cleared = true;
            })
            .on('select2:opening', e => {
                if ( this.$elem[0].cleared ) {
                    e.preventDefault();
                    this.$elem[0].cleared = false;
                }
            });
    }
}

export {
	Select2
};