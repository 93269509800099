import functions from '../functions.js';

$(function () {
    // global datepicker init with automatic "dateTo" set if provided
    let displayDate = new Date('2019-06-01');
    let now = functions.cleanDate(new Date);
    if ( now > displayDate ) {
        displayDate = now;
    }

    $('.datepicker-range').each((i, elem) => {
        const $datepicker = $(elem);
        const minDate = $datepicker.attr('data-min');
        const maxDate = $datepicker.attr('data-max');
        const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
        const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
        const disable = $datepicker.attr('data-disable') ? JSON.parse($datepicker.attr('data-disable')) : [];
        const duration = Number($datepicker.attr('data-duration')) - 1;


        $datepicker.flatpickr({
            mode: "range",
            showMonths: functions.isMobile() ? 1 : 2,
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            disable: disable,
            defaultDate: defaultDate,
            minDate: minDate && minDate >= now ? minDate : now,
            maxDate: maxDate ? maxDate : null,
            disableMobile: true,
            prevArrow: '<i class="la la-arrow-circle-left la-fw la-2x pr-2"></i>',
            nextArrow: '<i class="la la-arrow-circle-right la-fw la-2x pl-2"></i>',
            locale: {
                rangeSeparator: ' - '
            },
            onDayCreate(dObj, dStr, fp, dayElem){

                let to = new Date(maxDate);
                let weekday =  new Date(dayElem.dateObj).getDay();
                weekday = '' + (weekday ? weekday : 7);
                to.setHours(0, 0, 0, 0);

                if ( dayElem.dateObj > to || (arrival.length && $.inArray( weekday , arrival ) == -1) ) {
                    dayElem.classList.add('unselectable');
                }
            },
            onChange: function(selectedDates, dateStr, instance) {
                // duration
                if (selectedDates[0] && ! selectedDates[1] && duration) {
                    let date = new Date(selectedDates[0]);
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setMilliseconds(0);

                    let date2 = new Date(selectedDates[0]);
                    date2.setHours(0);
                    date2.setMinutes(0);
                    date2.setSeconds(0);
                    date2.setMilliseconds(0);
                    date2.setDate(date.getDate() + duration);

                    function pad(s) { return (s < 10) ? '0' + s : s; }
                    date = new Date(date);
                    date = [date.getFullYear(), pad(date.getMonth()+1), pad(date.getDate())].join('-');

                    date2 = new Date(date2);
                    date2 = [date2.getFullYear(), pad(date2.getMonth()+1), pad(date2.getDate())].join('-')

                    instance.setDate(date + ' - ' + date2);
                    instance.close();
                }
            },
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    });

    $('.datepicker').not('.departure-picker').each((i, elem) => {
        const $datepicker = $(elem);
        $datepicker.flatpickr({
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            disableMobile: true,
            prevArrow: '<i class="la la-angle-left pr-2"></i>',
            nextArrow: '<i class="la la-angle-right pl-2"></i>',
            onChange(selectedDates, dateStr, instance) {

            },
            onOpen(selectedDates, dateStr, instance) {

            }
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    }); 
});