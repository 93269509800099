import _default from "keen-slider";

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        InnerBlocks
    } = window.wp.editor;

    const ALLOWED_BLOCKS = ['custom-gutenberg/hero-slide'];

    registerBlockType('custom-gutenberg/hero-slider', {
        // built-in attributes
        title: 'Hero Slider',
        description: 'Custom Hero Slider',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // built-in functions
        edit() {

            const MY_TEMPLATE = [
                [ 'custom-gutenberg/hero-slide', {} ],
            ];


            return (
                <div class="position-relative">
                    <div id="hero-slider" class="keen-slider mb-4" style={ { opacity: '1' } }>
                        <InnerBlocks allowedBlocks={ ALLOWED_BLOCKS } template={ MY_TEMPLATE } />
                    </div>
                    <div class="keen-slider__arrows">
                        <div data-dir="prev"><i
                                class="la la-angle-left"></i></div>
                        <div data-dir="next"><i
                                class="la la-angle-right"></i></div>
                    </div>
                </div>
            );
        },

        save() {

            return (
                <div class="home-hero position-relative">
                    <div id="hero-slider" class="keen-slider">
                        <InnerBlocks.Content />
                    </div>
                    <div class="keen-slider__arrows">
                        <div data-dir="prev"><i
                                class="la la-angle-left"></i></div>
                        <div data-dir="next"><i
                                class="la la-angle-right"></i></div>
                    </div>
                </div>
            );
        },
    });
}