import functions from '../functions.js';
import Slider from "../components/Slider";
import { details } from './details';

$(() => {
    detailsTravel.init();
})

const detailsTravel = {
    $details: null,
    $form: null,
    form: null,
    departures: 0,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
        partnerId: null
    },
    init() {

        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');

        if(! this.$details[0] || this.params.objectGroupId != 6) {
            return;
        }

        this.params.partnerId = this.$details.attr('data-partnerid');
        this.$form = $('.availability-form');
        this.form = this.$form[0];
        this.departures = $('.booking-calendar-wrapper').length;

        this.getParams();

        if (this.departures) {
            this.initDepartureCalendar();
            this.setDefaultDeparture();
            this.setUnitRates();

            $('.departure').on('click', e => {
                $('.departure').removeClass('active');

                const $target = $(e.currentTarget);
                $target.addClass('active');
                this.params.dateFrom = $target.attr('data-datefrom');
                $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
                this.params.dateTo = $target.attr('data-dateto');
                this.params.guests = $('[name=guests]').val();

                functions.setUrlData({guests: this.params.guests, 'dateFrom': this.params.dateFrom }, true);

            });

            $('[name=guests]').on('change', e => {
                const $target = $(e.currentTarget);
                this.params.guests = $target.val();
                functions.setUrlData({guests: this.params.guests, dateFrom: this.params.dateFrom}, true, true);
            });

        } else {
            functions.setFormData(this.form, this.params);

            $('[name=guests]').on('change', e => {
                let params = functions.getFormData(this.$form);
                functions.setUrlData(params, true, true);
            });

            $('[name=dates]').on('change', e => {
                let params = functions.getFormData(this.$form);
                if (params.dates.indexOf(' - ') !== -1) {
                    functions.setUrlData(params, true, true);
                }
            });
        }

        this.params.objectGroupId = this.$details.attr('data-objectgroupid');
        this.params.objectId = this.$details.attr('data-objectid');
        this.params.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;


        this.getCalculation();


        this.$form.on('submit', e => {
            e.preventDefault();
            $(".spinner-border").removeClass('d-none')
            let params = functions.getFormData($(e.currentTarget));
            functions.setUrlData(params, true);
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.getCalculation();
            if (this.departures) {
                this.setUnitRates();
            }

        });

        detailsTravel.loadReviews();

        $('[data-view-all-itinerary]').on('click', e => {
            if ($('[data-view-all-itinerary]').find('i').hasClass('la-angle-down')) {
                $('.overflow-hidden[data-index]').removeClass('d-none');
                $('[data-view-all-itinerary]').find('i').removeClass('la-angle-down').addClass('la-angle-up');
            } else {
                $('.overflow-hidden[data-index]').addClass('d-none');
                $('.overflow-hidden[data-index="0"], .overflow-hidden[data-index="1"], .overflow-hidden[data-index="2"]').removeClass("d-none");
                $('[data-view-all-itinerary]').find('i').removeClass('la-angle-up').addClass('la-angle-down');
            }

        });

        $('[id^=collapseDay]').each((i, elem) => {
            $(elem)[0].addEventListener('show.bs.collapse', function (e) {
                if($(e.target).hasClass('collapseAcc')) return;
                
                $(elem).closest(".row").find(".view-all-images").removeClass('d-none');
                $(elem).closest(".row").find(".la-angle-down").removeClass('la-angle-down').addClass('la-angle-up');
            })
            $(elem)[0].addEventListener('hide.bs.collapse', function (e) {
                if($(e.target).hasClass('collapseAcc')) return;

                $(elem).closest(".row").find(".view-all-images").addClass('d-none');
                $(elem).closest(".row").find(".la-angle-up").removeClass('la-angle-up').addClass('la-angle-down');
            })
        });
    },
    getParams() {
        this.params = details.getParams(this.params);

        if(this.params.dates) {
            this.splitCalendarDates(this.params.dates);
        }
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    getCalculation() {

        const calculation = details.getCalculation(this.params);
        if(calculation) {
            this.loader(this.params.unitId ? $('.unit[data-unitid=' + this.params.unitId + ']').find('.unit-spinner') : null);
            calculation
                .done(response => {
                    this.calculationDone(response);
                }).fail(error => {
                    console.log(error);
                });
        } else {

            $('.btn-booking').toggleClass('d-none', true);
            $('.total').toggleClass('d-none', true);
            $('.not-available').toggleClass('d-none', true);
            $('.price-from').toggleClass('d-none', false);
        }
    },
    calculationDone(response) {
        if(response.status) {
            const data = response.data || [];

            //console.log(data);

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');

                const $btnBooking = $unit.find('.btn-booking[data-unitId=' + unitId + ']');

                if(details.isBookable(unitData)) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    $unit.find('.total').removeClass('d-none');
                    $unit.find('.price-from').addClass('d-none');
                    $unit.find('.not-available').addClass('d-none');

                    $unit.find('[data-total]').text(functions.formatMoney(total, 0, '.', ','));
                    $('.btn-booking').toggleClass('d-none', false);

                    let priceOld = details.getCalcTotalOld(calcItems);

                    $unit.find('[data-totalold]').closest('del').addClass('d-none');
                    console.log(priceOld, total);
                    if(priceOld > total) {
                        $unit.find('[data-totalold]').closest('del').removeClass('d-none');
                        $unit.find('[data-totalold]').text(functions.formatMoney(priceOld, 0, '.', ','));
                    }

                    if($btnBooking.attr('data-href')) {
                        $btnBooking.attr('data-href',
                            $btnBooking.attr('data-href').split('?')[0] +
                            '?objectGroupId=' + this.params.objectGroupId +
                            '&unitId=' + unitId +
                            '&dateFrom=' + this.params.dateFrom +
                            '&dateTo=' + this.params.dateTo +
                            '&guests=' + this.params.guests +
                            '&addAllOptionals=' + 1 +
                            '&language=' + functions.getLang()
                        );
                    }

                    $btnBooking.on('click', e => {
                        e.preventDefault();
                        location = $(e.currentTarget).attr('data-href');
                    });
                    
                } else {
                    $unit.find('.total').addClass('d-none');
                    $unit.find('.price-from').addClass('d-none');
                    $unit.find('.not-available').removeClass('d-none');
                    $btnBooking.addClass('d-none');
                }

                /*let error = details.getCalcErrorMessage(this.params.calcErrorMessages, unitData);
                $unit.find('.unit-error-container').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-error-container').text(error);*/
            });
        }
    },
    loader($target = null) {
        $('.units').addClass('fade');
        $target ? $target.addClass('active') : $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $target ? $target.removeClass('active') : $('.unit-spinner').removeClass('active');
        }, 600);
        setTimeout(() => {
            $('.units').removeClass('fade');
        }, 800);
    },
    loadReviews() {
        $('.reviews-more').on('click', function (e) {
            let $button = $(e.currentTarget);
            let perPage = 3;
            let page = +$button.data('page') + 1;
            let total = Math.round(+$button.data('total') / perPage);

            $.post('/services/reviews/', { language : functions.getLang(), perPage : perPage, page: page, objectId: $button.data('objectid') }).then(response => {
                if (response.status == true) {
                    $button.data('page', page);
                    if (total <= page) {
                        $button.attr('disabled', 'disabled');
                    }
                    $(".reviews").append(response.html);
                }
            });
        });
    },
    initDepartureCalendar() {
        const $bookingCalendar = $('#booking-calendar-slider');

        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 8;

            let slidesPerView = length < perView ? length : perView;

            if(length < perView) {
                $('.booking-calendar-wrapper').addClass('col-lg-' + (slidesPerView + 2));
                $('.booking-calendar-wrapper').addClass('mx-auto');
            }

            this.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: functions.isMobile() || length > slidesPerView,
                slides: {
                    perView: slidesPerView,
                    spacing: 0,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 6,
                        //centered: true,
                        slides: {
                            perView: 6.2,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.5,
                        },
                    },
                },
            });
        }
    },
    setDefaultDeparture() {

        let $date = $('[data-isavailable="1"]:first');
        let $dateSelected = $('[data-datefrom=' + this.params.dateFrom + ']');
        this.params.dateFrom = ! this.params.dateFrom ? $date.attr('data-datefrom') : ( ! $dateSelected.attr('data-isavailable') ? $date.attr('data-datefrom') : this.params.dateFrom);

        if ($('[data-datefrom=' + this.params.dateFrom + ']').length) {
            $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
            this.params.dateTo = $('[data-datefrom=' + this.params.dateFrom + ']').attr('data-dateto');
        } else {

            $date.addClass('active');
            this.params.dateFrom = $date.attr('data-datefrom');
            this.params.dateTo = $date.attr('data-dateto');
        }

        if(this.params.guests) {
            $('[name=guests]').val(this.params.guests);
            $('.input-guests')[0]._inputGuests.elemChange($('.input-guests')[0]._inputGuests);
        }

        //this.params.guests = $('[name=guests]').val();

        functions.setUrlData({'guests': this.params.guests, 'dateFrom': this.params.dateFrom }, true);
    },
    setUnitRates() {
        if(this.params.dateFrom) {
            this.loader();

            const $units = $('.unit[data-unitid]');

            $units.find('.price-from').removeClass('d-none');
            $units.find('.total').addClass('d-none');

            setTimeout(() => {

                const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + ']');
                const rates = JSON.parse($departure.attr('data-rates'));

                Object.entries(rates).forEach(item => {
                    const unitId = item[0];
                    const rate = item[1];

                    const $unit = $('.unit[data-unitid="' + unitId + '"]');
                    let guests = $('[name=guests]').val();

                    if ( ! guests || guests == '0,0') {

                        console.log(unitId, this.params.dateFrom, rate.price);

                        $unit.find('[data-old]').toggleClass('d-none', ! rate.priceWithDiscount);
                        $unit.find('[data-old]').text(rate.priceWithDiscount ? rate.price : '');
                        $unit.find('[data-from]').text(rate.priceWithDiscount ? rate.priceWithDiscount : rate.price);
                    }
                });

            }, 1000);
        }
    }
};
