import functions from '../functions';
import { list } from './list';

$(() => {
    listTravel.init();
});

const listTravel = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {
        _page: 1,
        perPage: 12,
        language: functions.getLang(),
        arrivalDateFrom: '',
        destination: '',
        countryId: '',
        objectGroupId: 6,
        guests: '',
        hasArrival: 1,
        hasDiscount: null,
    },
    params: {},
    isHome: false,
    $listItems: null,
    filterParams: {
        tagCategoryId: null,
        difficulty: null,
        sortBy: null,
        objectName: null,
        hasDiscount: null,
    },
    mainFields: ['arrivalDateFrom', 'destination', 'sortBy', '_page'],
    init() {
        this.$form = $('.search-form.search-travel');
        this.form = this.$form[0];

        if( ! this.form) {
            return;
        }

        this.$listItems = $('.list-items');

        this.listURL = this.$form.last().attr('data-listurl');
        this.defaults.objectGroupId = this.$form.last().attr('data-objectgroupid');
        this.defaults.countryId = this.defaults.destination = this.$form.last().attr('data-countryid');
        this.filterParams.tagCategoryId = this.$form.last().attr('data-tagcategoryid');
        this.filterParams.tagCategoryIds = this.$form.last().attr('data-tagcategoryids');
        this.filterParams.hasDiscount = this.$form.attr('data-hasdiscount');

        this.$form.on('submit', e => {
            const $target = $(e.target);
            e.preventDefault();

            this.isHome = $target.attr('data-ishome') || this.isHome;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                this.params.countryId = this.params.destination = $target.attr('data-countryid');
                this.params.perPage = $target.attr('data-perpage') || this.defaults.perPage;
            }

            this.params = $.extend({}, this.defaults, functions.getFormData($target));

            if (this.filterParams.tagCategoryId) {
                this.params.tagCategoryId = this.filterParams.tagCategoryId;
            }

            this.params._page = this.defaults._page;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');

                location = $target.attr('data-listurl') + '#' + $.param(Object.entries(this.params).reduce((a,[k,v]) => (v == null || v == "" ? a : (a[k]=v, a)), {}));
            } else {
                functions.setUrlData(this.params, true, true);
            }

        });

        this.getParams();
        functions.setFormData(this.form, this.params);
        this.params.guests = $('[name=guests]').val();
        this.find(this.params);

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.params.destination = '';
            $(this.form.destination).val('').trigger('change');
        });

        $('.btn-reset').on('click', e => {
            e.preventDefault();
            this.params.tagCategoryId = '';
            this.params.difficulty = '';
            $('[data-difficulty]').removeClass("active");
            $('[data-tagcategoryid]').removeClass("active");
            functions.setUrlData(this.params, true);
        });

        let tagCategoryIds = this.params.tagCategoryId.split(',');
        $.each(tagCategoryIds, function (i, tagCategoryId) {
            $('a[data-tagcategoryid="'+tagCategoryId+'"]').addClass('active');
        });

        if (this.params.difficulty) {
            $('[data-difficulty="'+this.params.difficulty+'"]').addClass('selected');
            $('[data-difficulty]').slice(0,this.params.difficulty).addClass("active");
        }

        $('a[data-tagcategoryid]').on('click', e => {
            e.preventDefault();
            this.params._page = 1;
            const $target = $(e.target);

            if ( ! $target.hasClass('active')) $target.addClass('active');
            else $target.removeClass('active');

            this.setTagCategoryId();

            functions.setUrlData(this.params, true);
        });

        $('[data-difficulty]').on('click', e => {
            e.preventDefault();
            this.params._page = 1;
            const $target = $(e.target);

            if ( ! $target.hasClass('selected')) {
                $target.addClass('selected');
                $('[data-difficulty]').removeClass("active");
                $('[data-difficulty]').slice(0,$target.attr('data-difficulty')).addClass("active");
                this.params.difficulty = $target.attr('data-difficulty');
            }
            else {
                $('[data-difficulty]').removeClass('active').removeClass('selected');
                this.params.difficulty = null;
            }

            functions.setUrlData(this.params, true);
        });


        $(window).on('hashchange', e => {
            this.getParams();

            this.stopChangeEvents = true;

            functions.scrollToElement(this.$listItems, 100);
            this.find(this.params);
            this.stopChangeEvents = false;
        });
    },
    getParams() {      
        this.params = list.getParams(this.params, this.defaults, this.filterParams);

        if(this.params.arrivalDateFrom) {
            
            let dateFrom = new Date(this.params.arrivalDateFrom);
            let month = dateFrom.getMonth() + 1;
            
            let lastDateOfMonth = new Date(dateFrom.getFullYear(), month, 0);
            this.params.arrivalDateTo = lastDateOfMonth.getFullYear() + '-' + (lastDateOfMonth.getMonth() + 1) + '-' + lastDateOfMonth.getDate();
        }
    },
    setTagCategoryId() {
        let tagCategoryIds = [];
        $('[data-tagcategoryid]').each((index, target) => {
            const $target = $(target);
            if($target.hasClass('active')) {
                tagCategoryIds.push($target.attr('data-tagcategoryid'));
            }
        });
        this.params.tagCategoryId = tagCategoryIds.join(",");
    },
    find(params) {
        list.find(params).then((response) => {
            list.findDone(response);
            // LOZAD
            window.observer.observe();
        });
    },

};