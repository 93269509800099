import _default from "keen-slider";

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        InnerBlocks
    } = window.wp.editor;

    const ALLOWED_BLOCKS = ['custom-gutenberg/image-slide'];

    registerBlockType('custom-gutenberg/image-slider', {
        // built-in attributes
        title: 'Image Slider',
        description: 'Custom Image Slider',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // built-in functions
        edit() {

            const MY_TEMPLATE = [
                [ 'custom-gutenberg/image-slide', {} ],
            ];


            return (
                <div class="row" id="image-slider">
                    <InnerBlocks allowedBlocks={ ALLOWED_BLOCKS } template={ MY_TEMPLATE } />
                </div>
            );
        },

        save() {

            return (
                <div class="position-relative image-slider">
                    <div class="container mb-5">
                        <div class="mt-0 mt-lg-2 keen-slider" id={ 'image-slider-' + (Math.floor(Math.random() * 100) + 1) }>
                            <InnerBlocks.Content />
                        </div>
                        <div className="keen-slider__arrows">
                            <div data-dir="prev"><i
                                className="la la-angle-left"></i></div>
                            <div data-dir="next"><i
                                className="la la-angle-right"></i></div>
                        </div>
                    </div>
                </div>
            );
        },
    });
}