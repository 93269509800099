import './details/details';
import './details/details-travel';
//import './details/details-accommodation';
//import Slider from "./components/Slider";

$(() => {

    if($('.nav-top').length) {
        $(window).scroll(e => {

            $(window).scrollTop() > 500 
                ? $('.nav-top').addClass('visible') 
                : $('.nav-top').removeClass('visible');

        });
    }
});