import functions from './functions';

$(() => {

    $('[id^=enquiryModal]').on('shown.bs.modal', e => {
        let params = functions.getUrlData(true);
        let dates = params.dates;

        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            params.dateFrom = aDates[0] ? aDates[0] : null;
            params.dateTo = aDates[1] ? aDates[1] : null;
        }

        const $flatpickr = $(e.currentTarget).find($('.datepicker-range'))[0]._flatpickr;

        if(params.dateFrom) {
            const $departure = $('.departure[data-datefrom=' + params.dateFrom + ']');
            params.dateTo = $departure[0] ? $departure.attr('data-dateto') : params.dateTo;
            $flatpickr.setDate(params.dateTo ? [params.dateFrom, params.dateTo] : [params.dateFrom]);
        }

        const unitId = $(e.currentTarget).attr('data-unitid');
        params.guests = params.guests ? params.guests : $('.unit[data-unitid=' + unitId + ']').find('[name=guests]').val();
        
        if(params.guests) {
            const $guests = $(e.currentTarget).find('[name=guests]');
            $guests.val(params.guests).trigger('change');
        }
    });

    console.log('ddddd');

    $('form#inquiry').on('submit', e => {
        e.preventDefault();

        const $form = $(e.currentTarget);

        let params = {};
        $.each($(e.currentTarget).serializeArray(), function (i, field) {
            params[field.name] = field.value;
        });

        if(params['dates'] && params['quantity']) {
            $('.contact-btn').addClass('d-none');
            $('.contact-spinner').removeClass('d-none');
            $('.contact-spinner').addClass('d-flex');

            params['language'] = functions.getLang();
    
            params['requestId'] = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);
    
            grecaptcha.ready(function () {
                grecaptcha.execute('6Ld5Ej0qAAAAAG-FkITbfEki8tBo0559jiNK2aeX', { action: 'contact' }).then(function (token) {
                    params['gRecaptchaToken'] = token;
                    params = functions.getDataForUrl(params);
                    $.post('/services/inquiry/', params).then(response => {
    
                        console.log(response);
    
                        /*response.data = response.status 
                            ? 'Vaš upit je uspješno zaprimljen. Odgovoriti ćemo Vam u najkraćem mogućem roku.'
                            : 'Poštovani, došlo je do pogreške. Pokušajte ponovno kasnije.';*/

                        $('form#inquiry').addClass('d-none');
                        $(".response-output").removeClass('d-none').html(response.data);

                        $('.contact-btn').removeClass('d-none');
                        $('.contact-spinner').removeClass('d-flex');
                        $('.contact-spinner').addClass('d-none');
    
                        setTimeout(() => { $('[id^=enquiryModal]').modal('hide');$(".response-output").addClass('d-none');$('form#inquiry').removeClass('d-none'); }, 6000);
    
                        if (response.status == true) {
                            e.currentTarget.reset();
                        }
                    });
                });
            });
        }

    });

});

