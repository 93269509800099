import _default from "keen-slider";

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;

    const {
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;

    const {
        PanelBody,
        IconButton,
        TextControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/simple-card-image-right', {
        // built-in attributes
        title: 'Simple Card With Image To the Right',
        description: 'Simple Card With Image To the Right',
        icon: 'slides',
        category: 'custom-components',
        example: {},
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            backgroundImage: {
                type: 'string',
                default: ''
            },
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: 'SAZNAJTE VIŠE'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                link,
                backgroundImage,
                title,
                description,
                viewMore
            } = attributes;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({backgroundImage: image.sizes.full.url});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            function onViewMoreChange(viewMore) {
                setAttributes({viewMore: viewMore});
            }

            return ([
                    <InspectorControls style={ { marginBottom: '40px' } }>
                        <PanelBody title={ 'Link' }>
                            <p><strong>Enter url: (ex: /putovanja)</strong></p>
                            <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                        </PanelBody>
                        <PanelBody title={ 'Background Image Settings' }>
                            <MediaUpload
                                onSelect={ onSelectImage }
                                type="image"
                                value={ backgroundImage }
                                render={ ( { open } ) => (
                                    <IconButton
                                        onClick={ open }
                                        icon="upload"
                                        classNames="editor-media-placeholder__button is-button is-default is-large">
                                        Select Background Image
                                    </IconButton>
                                ) } />
                        </PanelBody>
                        <PanelBody title={ 'Content' }>
                            <p><strong>Title</strong></p>
                            <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                            <p><strong>Description</strong></p>
                            <TextControl value={ description } onChange={ onDescriptionChange }></TextControl>
                            <p><strong>Button</strong></p>
                            <TextControl value={ viewMore } onChange={ onViewMoreChange }></TextControl>
                        </PanelBody>
                    </InspectorControls>
                    ,
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <div>
                                <RichText
                                    key="editable"
                                    tagName="div"
                                    class="title"
                                    placeholder="Title"
                                    value={ title }
                                    onChange={ onTitleChange }
                                    style={ { color: '#000', fontSize: '18px' } } />
                                <RichText
                                    key="editable"
                                    tagName="div"
                                    class="description"
                                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec ornare sapien eu est sociis phasellus hendrerit quam semper."
                                    value={ description }
                                    onChange={ onDescriptionChange }
                                    style={ { color: '#000', fontSize: '15px', marginTop: '1rem' } } />
                                <RichText
                                    key="editable"
                                    tagName="div"
                                    style={ { fontSize: '15px', display: 'inline-block', backgroundColor: '#E84927', padding: '5px', color: '#fff', marginTop: '1rem' } }
                                    placeholder="SAZNAJTE VIŠE"
                                    value={ viewMore }
                                    onChange={ onViewMoreChange } />
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className="w-100 h-100 cover-image bg-light"
                                 style={{ height: 350, backgroundImage: `url(${backgroundImage})` }} data-background-image={ backgroundImage }>
                            </div>
                        </div>
                    </div>

                ]
            );
        },

        save({ attributes }) {

            const {
                link,
                backgroundImage,
                title,
                description,
                viewMore
            } = attributes;

            return (
                <div className="container py-lg-5 py-0">
                    <div className="row gx-lg-5 align-items-stretch">
                        <div className="col-12 col-lg-6 order-lg-1 order-2 pb-lg-5">
                            <h2 className="mt-lg-5 mt-3 mb-4" dangerouslySetInnerHTML={{ __html: title }} />
                            <p className="text-muted" dangerouslySetInnerHTML={{ __html: description }} />
                            {(() => {
                                if (link){
                                    return (
                                        <a href={ link }
                                           className="w-auto rounded-0 btn btn-warning text-white me-2 mt-4 mt-lg-0">
                                            { viewMore }
                                        </a>
                                    )
                                }
                                return null;
                            })()}
                        </div>
                        <div className="col-12 col-lg-6 order-lg-2 order-1">
                            <div className="border-triangles lozad w-100 h-100 cover-image bg-dark"
                                 style="min-height: 340px;" data-background-image={ backgroundImage }>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    });
}