import Slider from "./components/Slider";
import "./components/datepicker";
import {Select2} from "./components/select2";
import functions from "./functions";
import {FormGuide, FormGuideDefaults} from "./components/form-guide";
import { InputGuests } from "./components/input-guests.js";
//import {SimpleGuests, SimpleGuestsDefaults} from "./components/simple-guests.js";
import inputPopover from "./components/input-popover.js";

//SimpleGuestsDefaults.maxInfants = 0;
/*SimpleGuestsDefaults.adultsLabel = $('.simple-guests').attr('data-adultsLabel') || SimpleGuestsDefaults.adultsLabel;
SimpleGuestsDefaults.childrenLabel = $('.simple-guests').attr('data-childrenLabel') || SimpleGuestsDefaults.childrenLabel;
SimpleGuestsDefaults.childrenText = $('.simple-guests').attr('data-childrenText') || SimpleGuestsDefaults.childrenText;
SimpleGuestsDefaults.infantsLabel = $('.simple-guests').attr('data-infantsLabel') || SimpleGuestsDefaults.infantsLabel;
SimpleGuestsDefaults.guestsText = window.guestsText = $('.simple-guests').attr('data-guestsText') || SimpleGuestsDefaults.guestsText;
SimpleGuestsDefaults.infantsText = window.infantsText = $('.simple-guests').attr('data-infantsText') || SimpleGuestsDefaults.infantsText;
SimpleGuestsDefaults.applyText = $('.simple-guests').attr('data-applyText') || SimpleGuestsDefaults.applyText;*/

$(function () {

  $('#navMainMenu').on('show.bs.collapse', e => {
    $('.nav-main').addClass('show');
  });
  $('#navMainMenu').on('hide.bs.collapse', e => {
    $('.nav-main').removeClass('show');
  });

  $('body').on('click', '.dropdown-menu', e => {
    e.stopPropagation();
  });

  $('.input-guests').each((i, elem) => {
    elem._inputGuests = new InputGuests(elem);
  });

  /*$('.simple-guests').each((i, elem) => {
		elem._simpleGuests = new SimpleGuests(elem);
	});*/

  $('.form-guide').each((i, elem) => {
    new FormGuide(elem);
  });

  $('.select2').each((i, elem) => {
		elem._select2 = new Select2(elem);
	});

  inputPopover.apply();

  let listSlider = [];

  $('[id^=card-slider]').each((index, element) => {
      listSlider[index] = new Slider('#' + $(element).attr('id'), {
          rubberband: false,
          loop: true,
          arrows: true,
          slides: {
              perView: 1,
              spacing: 0,
          },
      });
  });

  $('.search-form label').on('click', e => {
    const $target = $(e.currentTarget);
    const $elem = $target.find('.select2-search__field');
    $elem[0] && $elem.focus();
  });

  $(document).on('click', '[data-scroll-to]', e => {
    e.preventDefault();
    const $this = $(e.target);
    let scrollTo = $this.attr('data-scroll-to').split(',');
    functions.scrollToElement(scrollTo[0], scrollTo[1] || 0);
  });

  $('#collapseItinerary').on('show.bs.collapse', e => {
      $('.show-itinerary').addClass('d-none');
  });
  $('#collapseItinerary').on('hide.bs.collapse', e => {
      $('.show-itinerary').removeClass('d-none');
  });

  if($('#hero-slider')[0]) {
    new Slider($('#hero-slider'), {
      rubberband: false,
      loop: true,
      arrows: true,
      pager: true,
      //autoplay: 5000,
      autoplay: 0,
      slides: {
        perView: 1,
        spacing: 0,
      },
      defaultAnimation: {
        duration: 1200,
      },
    });
  }

  let pagesSlider = [];

  if($('[id^=pages-slider]')[0]) {
    $('[id^=pages-slider]').each((i, elem) => {
      pagesSlider[i] = new Slider('#' + $(elem).attr('id'), {
        rubberband: false,
        loop: true,
        arrows: true,
        pager: false,
        slides: {
          perView: 4,
          spacing: 20,
        },
        defaultAnimation: {
          duration: 1200,
        },
        breakpoints: {
          '(max-width: 768px)': {
            slides: {
              perView: 1.2,
              spacing: 20,
              //spacing: 0,
            },
          },
        }
      });
    });
  }

  let catalogueSlider = [];

  $('[id^=catalogue-slider], [id^=image-slider]').each((i, elem) => {
    const length = $(elem).find('.keen-slider__slide').length;
    catalogueSlider[i] = new Slider('#' + $(elem).attr('id'), {
      rubberband: false,
      loop: false,
      arrows: true,
      pager: false,
      slides: {
        //origin: "center",
        perView: 3,
        spacing: 0,
      },
      breakpoints: {
        '(max-width: 992px)': {
          slides: {
            perView: 2.2,
            spacing: 0,
          },
        },
        '(max-width: 576px)': {
          slides: {
            perView: 1.2,
            spacing: 0,
          },
        },
      }
    });
  });

  let featuredSlider = [];

  if($('[id^=featured-slider]')[0]) {
    $('[id^=featured-slider]').each((i, elem) => {
      featuredSlider[i] = new Slider('#' + $(elem).attr('id'), {
        rubberband: false,
        loop: false,
        arrows: true,
        pager: false,
        slides: {
          perView: 2.5,
          spacing: 20,
        },
        defaultAnimation: {
          duration: 1200,
        },
        breakpoints: {
          '(max-width: 768px)': {
            slides: {
              perView: 1.2,
              spacing: 20,
            },
          },
        }
      });
    });
  }

  if(functions.getCookie('cookie_usage') !== 'accepted') {
    $("#cookie-bar").removeClass('d-none');
  }

  $('#cookie-bar-accept').on('click', function (e) {
    functions.setCookie('cookie_usage', 'accepted');
    $("#cookie-bar").addClass('d-none');
  });

  $('.accordion__header').removeAttr('data-item');

});